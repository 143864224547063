/**
* Template Name: HeroBiz
* Updated: Mar 10 2023 with Bootstrap v5.2.3
* Template URL: https://bootstrapmade.com/herobiz-bootstrap-business-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/**
* Check out variables.css for easy customization of colors, typography, and other repetitive properties
*/
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

a {
  color: var(--color-links);
  text-decoration: none;
}

a:hover {
  color: var(--color-links-hover);
  text-decoration: none;
}

#shadow-host-companion{
padding: 0px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}
p {
  margin: 0 auto;
  color: var(--color-secondary-light);
}
.main-color {
  color: var(--color-primary) !important;
}
.main-bg {
  background-color: var(--color-primary) !important;
}
.menuactive{
  border-bottom: 2px solid var(--color-primary);
}
.business-process-item:hover{
  color:var(--color-primary) !important;
}
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: var(--color-white);
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-to-top__button {
  background-color: var(--color-primary);
  color: #fff;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  border: 1px solid var(--color-secondary);
}

.scroll-to-top__button:hover {
  opacity: 1;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  animation-delay: -0.5s;
}
.services > ul.i-list {
  padding-left: 0;
  list-style: none;
}

.services > ul.i-list .list-title {
  display: inline-block;
  position: absolute;
}

.services > ul.i-list li {
  padding: 2px 0px;
}

.services > ul.i-list i.fa {
  margin-right: 7px;
}

.services > ul.i-list .list-item {
  margin-top: 3px;
  display: inline-block;
}

.services > ul.i-list.filled i.fa {
  color: white;
  font-size: 9px;
  padding: 5px;
  border-radius: 50%;
}

.services > ul.e-icon-list.filled li {
  padding: 2px 0px;
  line-height: 24px;
}

.services > ul.i-list.underline li {
  padding: 6px 0px;
  border-bottom: 1px solid #eee;
}

.services > ul.i-list.medium li {
  padding-bottom: 25px;
  position: relative;
}

.services > ul.i-list.medium .icon {
  margin-right: 25px;
  color: white;
  font-size: 25px;
  text-align: center;
  line-height: 68px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 1;
  /*background-image: url(../img/crease.svg) !important;
    -moz-background-size: 100% 100% !important;
    background-size: 100% 100% !important;
    background-position: center center !important;*/
}

.services > ul.i-list.medium .icon i.fa {
  margin: 0;
}

.services > ul.i-list.medium.bordered .icon {
  background: white;
  color: inherit;
  border: 2px solid #8fc135;
  font-size: 26px;
  color: #8fc135;
  position: relative;
  z-index: 1;
  box-shadow: 0 8px 22px rgba(0, 0, 0, 0.28);
}

.services > ul.i-list.medium .list-item {
  text-transform: uppercase;
}

.services > ul.i-list.large .icon {
  margin-right: 30px;
  background: #d0d0d0;
  color: white;
  font-size: 30px;
  text-align: center;
  line-height: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 8px 22px rgba(0, 0, 0, 0.28);
}

.services > ul.i-list.large .icon i.fa {
  margin: 0;
}

.services > ul.i-list.large.bordered .icon {
  background: inherit;
  color: inherit;
  border: 2px solid #8fc135;
  font-size: 30px;
  color: #8fc135;
}

.services > ul.i-list.large .list-item {
  text-transform: uppercase;
}

.services > ul.i-list .icon {
  float: left;
}

.services > ul.i-list.right {
  text-align: right;
}

.services > ul.i-list.right .icon {
  float: right;
}

.services > ul.i-list.right .icon {
  float: right;
  margin-right: 0;
  margin-left: 25px;
}

.services > ul.i-list.large.right .icon {
  float: right;
  margin-right: 0;
  margin-left: 30px;
}

.services > ul.i-list.large li {
  margin-bottom: 25px;
}

.services > ul.i-list .icon-content {
  overflow: hidden;
}

.services > ul.i-list .icon-content .title {
  margin-top: 5px;
  margin-bottom: 10px;
}

.left-line .iconlist-timeline {
  left: auto;
  right: 35px;
}

.iconlist-timeline {
  position: absolute;
  top: 1%;
  left: 32px;
  width: 1px;
  height: 99%;
  border-right-width: 1px;
  border-right-style: dashed;
  height: 100%;
  border-color: #ccc;
}
.i-list-item > .icon {
  background-color: var(--color-primary);
}
separator,
.testimonial-two,
.exp-separator-inner {
  border-color: var(--color-primary);
}
.exp-separator {
  border-color: var(--color-primary);
  border-top-width: 2px;
  margin-top: 10px;
  margin-bottom: 2px;
  width: 100%;
  max-width: 55px;
  border-top-style: solid;
  height: auto;
  clear: both;
  position: relative;
  z-index: 11;
}
.section-sub-title {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: Poppins;
}
.section-title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 0.45em;
  margin-bottom: 0.35em;
  color: #303133;
  font-family: Poppins;
  letter-spacing: -0.02em;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.text-center {
  text-align: center !important;
}
.center-separator .exp-separator-inner,
.center-separator.exp-separator {
  margin-left: auto;
  margin-right: auto;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

a {
  text-decoration: none !important;
}
/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header h2 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.section-header p {
  margin: 0 auto;
  color: var(--color-secondary-light);
}

@media (min-width: 1280px) {
  .section-header p {
    max-width: 80%;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: rgba(var(--color-secondary-rgb), 0.05);
  min-height: 40px;
  margin-top: 76px;
}

.breadcrumbs h2 {
  font-size: 30px;
  font-weight: 300;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: var(--color-secondary-light);
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 995;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--color-white);
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
  color: var(--color-white);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
  .contact-input{
    padding: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  padding: 15px 0;
  transition: all 0.5s;
  z-index: 997;
}

.header.sticked {
  background: var(--color-white);
  box-shadow: 0px 2px 20px rgba(var(--color-secondary-rgb), 0.1);
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 32px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.header .logo h1 span {
  color: var(--color-primary);
  font-weight: 500;
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  font-size: 16px;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 8px 23px;
  border-radius: 4px;
  transition: 0.3s;
  font-family: var(--font-secondary);
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--color-white);
  background: rgba(var(--color-primary-rgb), 0.85);
}

@media (max-width: 1279px) {
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    margin-right: 50px;
  }
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .mobile-nav-toggle {
    display: block !important;
    color: var(--color-secondary);
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    top: 20px;
    z-index: 9999;
    right: 20px;
  }

  .mobile-nav-toggle.bi-x {
    color: var(--color-white);
  }

  .mobile-nav-active {
    overflow: hidden;
    z-index: 9995;
    position: relative;
  }

  .mobile-nav-active .navbar {
    left: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(var(--color-secondary-rgb), 0.8);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Animated Hero Section
--------------------------------------------------------------*/
.hero-animated {
  width: 100%;
  min-height: 50vh;
  background: url("/public/img/hero-bg.png") center center;
  background-size: cover;
  position: relative;
  padding: 120px 0 60px;
}

.hero-animated h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.hero-animated h2 span {
  color: var(--color-primary);
}

.hero-animated p {
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero-animated .animated {
  margin-bottom: 60px;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 992px) {
  .hero-animated .animated {
    max-width: 45%;
  }
}

@media (max-width: 991px) {
  .hero-animated .animated {
    max-width: 60%;
  }
}

@media (max-width: 575px) {
  .hero-animated .animated {
    max-width: 80%;
  }
}

.hero-animated .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.hero-animated .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.hero-animated .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.hero-animated .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero-animated .btn-watch-video:hover {
  color: var(--color-primary);
}

.hero-animated .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .hero-animated h2 {
    font-size: 32px;
  }

  .hero-animated p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .hero-animated .btn-get-started,
  .hero-animated .btn-watch-video {
    font-size: 14px;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Carousel Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  padding: 0;
  background: var(--color-black);
  background: url("/public/img/hero-bg.png") center center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 140px 0 60px 0;
}

.hero .carousel-item {
  overflow: hidden;
}

@media (max-width: 640px) {
  .hero .container {
    padding: 0 60px;
  }
}

.hero h2 {
  color: var(--color-secondary);
  margin-bottom: 25px;
  font-size: 48px;
  font-weight: 300;
  animation: fadeInDown 1s both 0.2s;
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 30px;
  }
}

.hero p {
  color: var(--color-secondary-light);
  animation: fadeInDown 1s both 0.4s;
  font-weight: 500;
  margin-bottom: 30px;
}

.hero .img {
  margin-bottom: 40px;
  animation: fadeInDownLite 1s both;
}

.hero .btn-get-started {
  font-family: var(--font-secondary);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 32px;
  border-radius: 5px;
  transition: 0.5s;
  animation: fadeInUp 1s both 0.6s;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.hero .btn-get-started:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.hero .carousel-control-prev {
  justify-content: start;
}

@media (min-width: 640px) {
  .hero .carousel-control-prev {
    padding-left: 15px;
  }
}

.hero .carousel-control-next {
  justify-content: end;
}

@media (min-width: 640px) {
  .hero .carousel-control-next {
    padding-right: 15px;
  }
}

.hero .carousel-control-next-icon,
.hero .carousel-control-prev-icon {
  background: none;
  font-size: 26px;
  line-height: 0;
  background: rgba(var(--color-secondary-rgb), 0.4);
  color: rgba(var(--color-white-rgb), 0.98);
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero .carousel-control-next-icon {
  padding-left: 3px;
}

.hero .carousel-control-prev-icon {
  padding-right: 3px;
}

.hero .carousel-control-prev,
.hero .carousel-control-next {
  transition: 0.3s;
}

.hero .carousel-control-prev:focus,
.hero .carousel-control-next:focus {
  opacity: 0.5;
}

.hero .carousel-control-prev:hover,
.hero .carousel-control-next:hover {
  opacity: 0.9;
}

.hero .carousel-indicators li {
  cursor: pointer;
  background: rgba(var(--color-secondary-rgb), 0.5);
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

.hero .carousel-indicators li.active {
  opacity: 1;
  background: var(--color-primary);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDownLite {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*--------------------------------------------------------------
# Fullscreen Hero Section
--------------------------------------------------------------*/
.hero-fullscreen {
  width: 100%;
  min-height: 100vh;
  background: url("/public/img/hero-fullscreen-bg.jpg") center center;
  background-size: cover;
  position: relative;
  padding: 120px 0 60px;
}

.hero-fullscreen:before {
  content: "";
  background: rgba(var(--color-white-rgb), 0.85);
  position: absolute;
  inset: 0;
}

@media (min-width: 1365px) {
  .hero-fullscreen {
    background-attachment: fixed;
  }
}

.hero-fullscreen h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.hero-fullscreen h2 span {
  color: var(--color-primary);
}

.hero-fullscreen p {
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero-fullscreen .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.hero-fullscreen .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.hero-fullscreen .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.hero-fullscreen .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero-fullscreen .btn-watch-video:hover {
  color: var(--color-primary);
}

.hero-fullscreen .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .hero-fullscreen h2 {
    font-size: 32px;
  }

  .hero-fullscreen p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .hero-fullscreen .btn-get-started,
  .hero-fullscreen .btn-watch-video {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
# Static Hero Section
--------------------------------------------------------------*/
.hero-static {
  width: 100%;
  min-height: 50vh;
  background: url("/public/img/hero-bg.png") center center;
  background-size: cover;
  position: relative;
  padding: 120px 0 60px;
}

.hero-static h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.hero-static h2 span {
  color: var(--color-primary);
}

.hero-static p {
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero-static .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.hero-static .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.hero-static .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.hero-static .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero-static .btn-watch-video:hover {
  color: var(--color-primary);
}

.hero-static .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .hero-static h2 {
    font-size: 32px;
  }

  .hero-static p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .hero-static .btn-get-started,
  .hero-static .btn-watch-video {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
# Featured Services Section
--------------------------------------------------------------*/
.featured-services .service-item {
  padding: 30px;
  transition: all ease-in-out 0.4s;
  background: var(--color-white);
  height: 100%;
}

.featured-services .service-item .icon {
  margin-bottom: 10px;
}

.featured-services .service-item .icon i {
  color: var(--color-primary);
  font-size: 36px;
  transition: 0.3s;
}

.featured-services .service-item h4 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 24px;
}

.featured-services .service-item h4 a {
  color: var(--color-secondary);
  transition: ease-in-out 0.3s;
}

.featured-services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.featured-services .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0 60px 0 rgba(var(--color-secondary-rgb), 0.1);
}

.featured-services .service-item:hover h4 a {
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .about-img {
  position: relative;
  margin: 60px 0 0 60px;
}

.about .about-img:before {
  position: absolute;
  inset: -60px 0 0 -60px;
  z-index: -1;
  content: "";
  background: url("/public/img/about-bg.png") top left;
  background-repeat: no-repeat;
}

@media (max-width: 575px) {
  .about .about-img {
    margin: 30px 0 0 30px;
  }

  .about .about-img:before {
    inset: -30px 0 0 -30px;
  }
}

.about h3 {
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-weight: 300;
  font-size: 32px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .about h3 {
    font-size: 28px;
  }
}

.about .nav-pills {
  border-bottom: 1px solid rgba(var(--color-secondary-rgb), 0.2);
}

.about .nav-pills li + li {
  margin-left: 40px;
}

.about .nav-link {
  background: none;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-secondary);
  padding: 12px 0;
  margin-bottom: -2px;
  border-radius: 0;
  font-family: var(--font-secondary);
}

.about .nav-link.active {
  color: var(--color-primary);
  background: none;
  border-bottom: 3px solid var(--color-primary);
}

@media (max-width: 575px) {
  .about .nav-link {
    font-size: 16px;
  }
}

.about .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: var(--color-secondary);
}

.about .tab-content i {
  font-size: 22px;
  line-height: 0;
  margin-right: 8px;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients {
  padding: 0 0 60px 0;
}

.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .swiper-slide img:hover {
  filter: none;
  opacity: 1;
}

/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.cta {
  padding: 0;
  margin-bottom: 60px;
}

.cta .container {
  padding: 80px;
  background: rgba(var(--color-secondary-rgb), 0.1);
  border-radius: 15px;
}

@media (max-width: 992px) {
  .cta .container {
    padding: 60px;
  }
}

.cta .content h3 {
  color: var(--color-secondary);
  font-size: 48px;
  font-weight: 700;
}

.cta .content h3 em {
  font-style: normal;
  position: relative;
}

.cta .content h3 em:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  height: 10px;
  background: rgba(var(--color-primary-rgb), 0.5);
  z-index: -1;
}

.cta .content p {
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 18px;
}

.cta .content .cta-btn {
  color: var(--color-white);
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  background: rgba(var(--color-primary-dark-rgb), 0.9);
}

.cta .content .cta-btn:hover {
  background: var(--color-primary);
}

.cta .img {
  position: relative;
}

.cta .img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(var(--color-white-rgb), 0.5);
  border-radius: 15px;
  transform: rotate(12deg);
}

.cta .img:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(var(--color-white-rgb), 0.9);
  border-radius: 15px;
  transform: rotate(6deg);
}

.cta .img img {
  position: relative;
  z-index: 3;
  border-radius: 15px;
}

/*--------------------------------------------------------------
# On Focus Section
--------------------------------------------------------------*/
.onfocus {
  padding: 0;
}

.onfocus .video-play {
  min-height: 400px;
  background: linear-gradient(
      rgba(var(--color-black-rgb), 0.4),
      rgba(var(--color-black-rgb), 0.7)
    ),
    url("/public/img/gov-dataset.jpg") center center;
  background-size: cover;
}

.onfocus .content {
  background: linear-gradient(
      rgba(var(--color-primary-rgb), 0.5),
      rgba(var(--color-primary-rgb), 0.8)
    ),
    url("/public/img/onfocus-content-bg.jpg") center center;
  background-size: cover;
  color: rgba(var(--color-white-rgb), 0.8);
  padding: 40px;
}
.onfocus .data-process {
  min-height: 400px;
  background: linear-gradient(
      rgba(var(--color-black-rgb), 0.4),
      rgba(var(--color-black-rgb), 0.7)
    ),
    url("/public/img/data-process.jpg") center center;
  background-size: cover;
}

.onfocus .content {
  background: linear-gradient(
      rgba(var(--color-primary-rgb), 0.5),
      rgba(var(--color-primary-rgb), 0.8)
    ),
    url("/public/img/onfocus-content-bg.jpg") center center;
  background-size: cover;
  color: rgba(var(--color-white-rgb), 0.8);
  padding: 40px;
}

@media (min-width: 768px) {
  .onfocus .content {
    padding: 80px;
  }
}

.onfocus .content h3 {
  font-weight: 600;
  font-size: 32px;
  color: var(--color-white);
}

.onfocus .content ul {
  list-style: none;
  padding: 0;
}

.onfocus .content ul li {
  padding-bottom: 10px;
}

.onfocus .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--color-primary);
}

.onfocus .content p:last-child {
  margin-bottom: 0;
}

.onfocus .content .read-more {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 12px 24px;
  border-radius: 5px;
  transition: 0.3s;
  display: -nline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  background: var(--color-secondary);
}

.onfocus .content .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
}

.onfocus .content .read-more:hover {
  background: rgba(var(--color-primary-rgb), 0.9);
  padding-right: 19px;
}

.onfocus .content .read-more:hover i {
  margin-left: 10px;
}

.onfocus .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(
    var(--color-primary) 50%,
    rgba(var(--color-primary-rgb), 0.4) 52%
  );
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.onfocus .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(var(--color-primary-rgb), 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.onfocus .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid var(--color-white);
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.onfocus .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid var(--color-white);
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.onfocus .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  border: 0;
  padding: 25px 20px;
  color: var(--color-secondary);
  box-shadow: 5px 5px 25px rgba(var(--color-secondary-rgb), 0.15);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0s;
  cursor: pointer;
  height: 100%;
}

.features .nav-link i {
  font-size: 32px;
  line-height: 0;
}

.features .nav-link h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 0 0;
  color: var(--color-secondary);
}

.features .nav-link:hover {
  color: var(--color-primary);
}

.features .nav-link.active {
  transition: 0.3s;
  background: var(--color-secondary)
    linear-gradient(
      rgba(var(--color-primary-rgb), 0.95),
      rgba(var(--color-primary-rgb), 0.6)
    );
  border-color: var(--color-primary);
}

.features .nav-link.active h4 {
  color: var(--color-white);
}

.features .nav-link.active i {
  color: var(--color-white) !important;
}

.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane.active {
  animation: fadeIn 0.5s ease-out;
}

.features .tab-pane h3 {
  font-weight: 600;
  font-size: 36px;
  color: var(--color-secondary);
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-bottom: 10px;
}

.features .tab-pane ul i {
  font-size: 24px;
  margin-right: 4px;
  color: var(--color-primary);
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .img {
  border-radius: 8px;
  overflow: hidden;
}

.services .img img {
  transition: 0.6s;
}

.services .details {
  padding: 50px 30px;
  margin: -100px 30px 0 30px;
  transition: all ease-in-out 0.3s;
  background: var(--color-white);
  position: relative;
  background: rgba(var(--color-white-rgb), 0.9);
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0 25px rgba(var(--color-black-rgb), 0.1);
}

.services .details .icon {
  margin: 0;
  width: 72px;
  height: 72px;
  background: var(--color-primary);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: var(--color-white);
  font-size: 28px;
  transition: ease-in-out 0.3s;
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  border: 6px solid var(--color-white);
}

.services .details h3 {
  color: var(--color-default);
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 22px;
  transition: ease-in-out 0.3s;
}

.services .details p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-item:hover .details h3 {
  color: var(--color-primary);
}

.services .service-item:hover .details .icon {
  background: var(--color-white);
  border: 2px solid var(--color-primary);
}

.services .service-item:hover .details .icon i {
  color: var(--color-primary);
}

.services .service-item:hover .img img {
  transform: scale(1.2);
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("/public/img/testimonials-bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(var(--color-secondary-dark-rgb), 0.8);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: var(--color-white);
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(var(--color-white-rgb), 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: var(--color-white);
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: rgba(var(--color-white-rgb), 0.6);
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: var(--color-yellow);
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(var(--color-white-rgb), 0.6);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(var(--color-white-rgb), 0.4);
  opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-white);
  opacity: 1;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing {
  background: rgba(var(--color-secondary-rgb), 0.04);
}

.pricing .pricing-item {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(var(--color-gray-rgb), 0.15);
  background: var(--color-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 4px solid var(--color-white);
  border-radius: 10px;
  overflow: hidden;
}

.pricing .pricing-header {
  background: linear-gradient(
      rgba(var(--color-secondary-rgb), 0.9),
      rgba(var(--color-secondary-rgb), 0.9)
    ),
    url("/public/img/pricing-bg.jpg") center center;
  background-size: cover;
  text-align: center;
  padding: 40px;
  margin: -60px -40px 0;
}

.pricing h3 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 36px;
  color: var(--color-white);
}

.pricing h4 {
  font-size: 48px;
  color: var(--color-white);
  font-weight: 400;
  font-family: var(--font-primary);
  margin-bottom: 0;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: rgba(var(--color-white-rgb), 0.6);
  font-size: 24px;
}

.pricing ul {
  padding: 30px 0;
  list-style: none;
  color: var(--color-gray);
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.pricing ul i {
  color: var(--color-primary);
  font-size: 36px;
  padding-right: 3px;
  line-height: 0;
}

.pricing ul .na {
  color: rgba(var(--color-gray-rgb), 0.5);
}

.pricing ul .na i {
  color: rgba(var(--color-gray-rgb), 0.5);
  font-size: 24px;
  padding-left: 4px;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 12px 40px;
  border-radius: 6px;
  color: var(--color-primary);
  transition: none;
  font-size: 16px;
  font-weight: 700;
  transition: 0.3s;
  border: 1px solid var(--color-primary);
}

.pricing .buy-btn:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.pricing .featured {
  border-color: var(--color-primary);
}

.pricing .featured .pricing-header {
  background: linear-gradient(
      rgba(var(--color-primary-rgb), 0.9),
      rgba(var(--color-primary-rgb), 0.9)
    ),
    url("/public/img/pricing-bg.jpg") center center;
}

.pricing .featured .buy-btn {
  background: var(--color-primary);
  color: var(--color-white);
}

/*--------------------------------------------------------------
# F.A.Q Section
--------------------------------------------------------------*/
@media (max-width: 991px) {
  .faq {
    padding: 0;
  }
}

.faq .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: var(--color-secondary);
}

.faq .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.faq .content p {
  font-size: 15px;
  color: var(--color-gray);
}

.faq .img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
}

.faq .accordion-item {
  border: 0;
  margin-top: 15px;
  box-shadow: 0px 5px 25px 0px rgba(var(--color-black-rgb), 0.06);
}

.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 40px 20px 60px;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  color: var(--color-default);
  text-align: left;
  background: var(--color-white);
  box-shadow: none;
  border-radius: 5px;
}

.faq .accordion-button:not(.collapsed) {
  color: var(--color-primary);
  border-bottom: 0;
  box-shadow: none;
}

.faq .question-icon {
  position: absolute;
  top: 14px;
  left: 25px;
  font-size: 20px;
  color: var(--color-primary);
}

.faq .accordion-button:after {
  position: absolute;
  right: 15px;
  top: 15px;
  color: var(--color-primary);
}

.faq .accordion-body {
  padding: 0 30px 25px 60px;
  border: 0;
  border-radius: 5px;
  background: var(--color-white);
  box-shadow: none;
}

/*--------------------------------------------------------------
# Portfolio Section
--------------------------------------------------------------*/
.portfolio .portfolio-flters {
  padding: 0;
  margin: 0 auto 30px auto;
  list-style: none;
  text-align: center;
}

.portfolio .portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  margin: 0 10px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-flters li:hover,
.portfolio .portfolio-flters li.filter-active {
  color: var(--color-primary);
}

.portfolio .portfolio-flters li:first-child {
  margin-left: 0;
}

.portfolio .portfolio-flters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .portfolio .portfolio-flters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

.portfolio .portfolio-item {
  position: relative;
  border: 1px solid var(--color-white);
  overflow: hidden;
  z-index: 1;
}

.portfolio .portfolio-item img {
  transition: all 0.3s;
}

.portfolio .portfolio-item:before {
  content: "";
  inset: 0;
  position: absolute;
  background: rgba(var(--color-secondary-rgb), 0.8);
  z-index: 2;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  inset: auto 40px 40px 40px;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  padding: 20px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-white);
  padding-right: 50px;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 50px;
  font-size: 24px;
  top: calc(50% - 14px);
  color: rgba(var(--color-white-rgb), 0.7);
  transition: 0.3s;
  line-height: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: var(--color-white);
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 14px;
  font-size: 28px;
}

.portfolio .portfolio-item:hover:before {
  visibility: visible;
  opacity: 1;
}

.portfolio .portfolio-item:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  inset: auto 10px 0 10px;
}

/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/
.team .team-member .member-img {
  border-radius: 8px;
  overflow: hidden;
}

.team .team-member .social {
  position: absolute;
  left: 0;
  bottom: -18px;
  right: 0;
  /* opacity: 0; */
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .team-member .social a {
  transition: color 0.3s;
  color: var(--color-white);
  background: var(--color-primary);
  margin: 0 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: 0.3s;
}

.team .team-member .social a i {
  line-height: 0;
  font-size: 16px;
}

.team .team-member .social a:hover {
  background: var(--color-primary-light);
}

.team .team-member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .team-member .member-info {
  padding: 30px 15px;
  text-align: center;
  box-shadow: 0px 2px 15px rgba(var(--color-black-rgb), 0.1);
  background: var(--color-white);
  margin: -50px 20px 0 20px;
  position: relative;
  border-radius: 8px;
}

.team .team-member .member-info h4 {
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 24px;
  color: var(--color-secondary);
}

.team .team-member .member-info span {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray);
}

.team .team-member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: var(--color-gray);
}

.team .team-member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.recent-blog-posts .post-box {
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent-blog-posts .post-box .post-img {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.recent-blog-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-box .meta {
  margin-top: 15px;
}

.recent-blog-posts .post-box .meta .post-date {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-primary);
}

.recent-blog-posts .post-box .meta .post-author {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-secondary);
}

.recent-blog-posts .post-box .post-title {
  font-size: 24px;
  color: var(--color-secondary);
  font-weight: 700;
  margin: 15px 0 0 0;
  position: relative;
  transition: 0.3s;
}

.recent-blog-posts .post-box p {
  margin: 15px 0 0 0;
  color: rgba(var(--color-secondary-dark-rgb), 0.7);
}

.recent-blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  margin-top: 15px;
}

.recent-blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-blog-posts .post-box:hover .post-title {
  color: var(--color-primary);
}

.recent-blog-posts .post-box:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .map {
  margin-bottom: 40px;
}

.contact .map iframe {
  border: 0;
  width: 100%;
  height: 400px;
}

.contact .info {
  padding: 40px;
  box-shadow: 0px 2px 15px rgba(var(--color-black-rgb), 0.1);
  overflow: hidden;
}

.contact .info h3 {
  font-weight: 600;
  font-size: 24px;
}

.contact .info p {
  color: var(--color-secondary-light);
  margin-bottom: 30px;
  font-size: 15px;
}

.contact .info-item + .info-item {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(var(--color-secondary-rgb), 0.15);
}

.contact .info-item i {
  font-size: 24px;
  color: var(--color-primary);
  transition: all 0.3s ease-in-out;
  margin-right: 20px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--color-secondary);
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: var(--color-secondary-light);
}

.contact .php-email-form {
  width: 100%;
  background: var(--color-white);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: var(--color-white);
  background: var(--color-red);
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: var(--color-white);
  background: var(--color-green);
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: var(--color-white);
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--color-green);
  border-top-color: var(--color-white);
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type="text"],
.contact .php-email-form input[type="email"],
.contact .php-email-form textarea {
  border-radius: 0px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input[type="text"]:focus,
.contact .php-email-form input[type="email"]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-secondary-light);
}

.contact .php-email-form input[type="text"],
.contact .php-email-form input[type="email"] {
  height: 48px;
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
  height: 130px;
}

.contact .php-email-form button[type="submit"] {
  background: var(--color-primary);
  border: 0;
  padding: 13px 50px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 0;
}

.contact .php-email-form button[type="submit"]:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: var(--color-white);
  opacity: 1;
  border: 1px solid var(--color-primary);
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(var(--color-secondary-rgb), 0.15);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-secondary-light);
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Blog Stylings
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Blog Home Posts List
--------------------------------------------------------------*/
.blog .posts-list article {
  box-shadow: 0 4px 16px rgba(var(--color-black-rgb), 0.1);
  padding: 30px;
  height: 100%;
}

.blog .posts-list article + article {
  margin-top: 60px;
}

.blog .posts-list .post-img {
  max-height: 240px;
  margin: -30px -30px 0 -30px;
  overflow: hidden;
}

.blog .posts-list .title {
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
}

.blog .posts-list .title a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .posts-list .title a:hover {
  color: var(--color-primary);
}

.blog .posts-list .meta-top {
  margin-top: 20px;
  color: var(--color-gray);
}

.blog .posts-list .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .posts-list .meta-top ul li + li {
  padding-left: 20px;
}

.blog .posts-list .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: rgba(var(--color-primary-rgb), 0.8);
}

.blog .posts-list .meta-top a {
  color: var(--color-gray);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .posts-list .content {
  margin-top: 20px;
}

.blog .posts-list .read-more a {
  display: inline-block;
  background: var(--color-primary);
  color: var(--color-white);
  padding: 8px 30px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .posts-list .read-more a:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

/*--------------------------------------------------------------
# Blog Details Page
--------------------------------------------------------------*/
.blog .blog-details {
  box-shadow: 0 4px 16px rgba(var(--color-black-rgb), 0.1);
  padding: 30px;
}

.blog .blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .blog-details .title {
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
  color: var(--color-secondary);
}

.blog .blog-details .content {
  margin-top: 20px;
}

.blog .blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .blog-details .content blockquote {
  overflow: hidden;
  background-color: rgba(var(--color-secondary-rgb), 0.06);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .blog-details .content blockquote p {
  color: var(--color-default);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--color-secondary);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .blog-details .meta-top {
  margin-top: 20px;
  color: var(--color-gray);
}

.blog .blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .blog-details .meta-top ul li + li {
  padding-left: 20px;
}

.blog .blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: rgba(var(--color-primary-rgb), 0.8);
}

.blog .blog-details .meta-top a {
  color: var(--color-gray);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid rgba(var(--color-secondary-rgb), 0.15);
}

.blog .blog-details .meta-bottom i {
  color: var(--color-secondary-light);
  display: inline;
}

.blog .blog-details .meta-bottom a {
  color: rgba(var(--color-secondary-rgb), 0.8);
  transition: 0.3s;
}

.blog .blog-details .meta-bottom a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags li + li::before {
  padding-right: 6px;
  color: var(--color-default);
  content: ",";
}

.blog .blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog .blog-details .meta-bottom .share i {
  padding-left: 5px;
}

.blog .post-author {
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 4px 16px rgba(var(--color-black-rgb), 0.1);
}

.blog .post-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog .post-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: var(--color-secondary);
}

.blog .post-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .post-author .social-links a {
  color: rgba(var(--color-secondary-rgb), 0.5);
  margin-right: 5px;
}

.blog .post-author p {
  font-style: italic;
  color: rgba(var(--color-gray-rgb), 0.8);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Sidebar
--------------------------------------------------------------*/
.blog .sidebar {
  padding: 30px;
  box-shadow: 0 4px 16px rgba(var(--color-black-rgb), 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: var(--color-secondary);
}

.blog .sidebar .sidebar-item + .sidebar-item {
  margin-top: 40px;
}

.blog .sidebar .search-form form {
  background: var(--color-white);
  border: 1px solid rgba(var(--color-secondary-rgb), 0.3);
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form input[type="text"]:focus {
  outline: none;
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: var(--color-primary);
  color: var(--color-white);
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: var(--color-default);
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: rgba(var(--color-default-rgb), 0.4);
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item {
  display: flex;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  margin-right: 15px;
}

.blog .sidebar .recent-posts h4 {
  font-size: 18px;
  font-weight: 400;
}

.blog .sidebar .recent-posts h4 a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: var(--color-primary);
}

.blog .sidebar .recent-posts time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: rgba(var(--color-default-rgb), 0.4);
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: var(--color-secondary-light);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid rgba(var(--color-secondary-light-rgb), 0.8);
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: var(--color-white);
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: rgba(var(--color-secondary-light-rgb), 0.8);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Blog Comments
--------------------------------------------------------------*/
.blog .comments {
  margin-top: 30px;
}

.blog .comments .comments-count {
  font-weight: bold;
}

.blog .comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .comments .comment .comment-img {
  margin-right: 14px;
}

.blog .comments .comment .comment-img img {
  width: 60px;
}

.blog .comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .comments .comment h5 a {
  font-weight: bold;
  color: var(--color-default);
  transition: 0.3s;
}

.blog .comments .comment h5 a:hover {
  color: var(--color-primary);
}

.blog .comments .comment h5 .reply {
  padding-left: 10px;
  color: var(--color-secondary);
}

.blog .comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .comments .comment time {
  display: block;
  font-size: 14px;
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin-bottom: 5px;
}

.blog .comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(var(--color-black-rgb), 0.1);
}

.blog .comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .comments .reply-form p {
  font-size: 14px;
}

.blog .comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form input:focus {
  box-shadow: none;
  border-color: rgba(var(--color-primary-rgb), 0.8);
}

.blog .comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: rgba(var(--color-primary-rgb), 0.8);
}

.blog .comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--color-secondary);
}

.blog .comments .reply-form .btn-primary:hover {
  background-color: rgba(var(--color-secondary-rgb), 0.8);
}

/*--------------------------------------------------------------
# Blog Home Pagination
--------------------------------------------------------------*/
.blog .blog-pagination {
  margin-top: 30px;
  color: var(--color-secondary-light);
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: var(--color-secondary);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: var(--color-primary);
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: var(--color-white);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  color: var(--color-white);
  font-size: 14px;
}

.footer .footer-content {
  background: var(--color-secondary);
  padding: 60px 0 30px 0;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

.footer .footer-content .footer-info h3 span {
  color: var(--color-primary);
}

.footer .footer-content .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: var(--font-primary);
  color: var(--color-white);
}

.footer .footer-content h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-white);
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.footer .footer-content h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: var(--color-primary);
  bottom: 0;
  left: 0;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-content .footer-links ul i {
  padding-right: 2px;
  color: var(--color-white);
  font-size: 12px;
  line-height: 1;
}

.footer .footer-content .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  color: rgba(var(--color-white-rgb), 0.7);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-content .footer-links ul a:hover {
  color: var(--color-white);
}

.footer .footer-content .footer-newsletter form {
  margin-top: 30px;
  background: var(--color-white);
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

.footer .footer-content .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

.footer
  .footer-content
  .footer-newsletter
  form
  input[type="email"]:focus-visible {
  outline: none;
}

.footer .footer-content .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--color-primary);
  color: var(--color-white);
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.footer .footer-content .footer-newsletter form input[type="submit"]:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
}

.footer .footer-legal {
  padding: 30px 0;
  background: var(--color-secondary-dark);
}

.footer .footer-legal .credits {
  padding-top: 4px;
  font-size: 13px;
  color: var(--color-white);
}

.footer .footer-legal .credits a {
  color: var(--color-primary-light);
}

.footer .footer-legal .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(var(--color-white-rgb), 0.1);
  color: var(--color-white);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .footer-legal .social-links a:hover {
  background: var(--color-primary);
  text-decoration: none;
}
